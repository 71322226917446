<template>
  <div class="tw-flex tw-mb-6 predefined-dimensions">
    <dimension
      v-for="({ key }, index) in dimensions"
      :key="key"
      :type="key"
      :index="index"
      :dimensions="dimensions"
      :data-test="key"
      class="tw-w-1/5"
      @select="selected(key)"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapActions, mapGetters } from 'vuex'

  import Dimension from './_subs/Dimension/index.vue'

  /**
   * @module component - predefinedDimensions
   */
  export default defineComponent({
    name: 'PredefinedDimensions',
    components: {
      Dimension
    },
    data () {
      return {
        dimensions: [
          {
            key: 's',
            length: { min: 0, max: 300 },
            width: { min: null, max: 135 },
            height: { min: null, max: 180 },
            weight: { min: null, max: 1300 }
          },
          {
            key: 'v',
            length: { min: 0, max: 440 },
            width: { min: null, max: 135 },
            height: { min: null, max: 160 },
            weight: { min: null, max: 1400 }
          },
          {
            key: 'vl',
            length: { min: 0, max: 410 },
            width: { min: null, max: 210 },
            height: { min: null, max: 220 },
            weight: { min: null, max: 800 }
          },
          {
            key: 'pl',
            length: { min: 0, max: 850 },
            width: { min: null, max: 240 },
            height: { min: null, max: 260 },
            weight: { min: null, max: 10000 }
          },
          {
            key: 'spl',
            length: { min: 0, max: 1360 },
            width: { min: null, max: 240 },
            height: { min: null, max: 270 },
            weight: { min: null, max: 28000 }
          }
        ]
      }
    },
    computed: {
      ...mapGetters('offers', [
        'getSelectedDimension',
        'getCurrentSearch'
      ]),
      /**
       * Creates a single computed property to watch all the dimensions at the same
       * time, instead of watching them individually.
       * @function searchDimensions
       */
      searchDimensions () {
        const {
          min_length: minLength,
          max_length: maxLength,
          max_width: width,
          max_height: height,
          max_weight: weight
        } = this.getCurrentSearch

        return [minLength, maxLength, width, height, weight].join('-')
      }
    },
    watch: {
      searchDimensions: function () {
        this.updateSelectedDimension()
      }
    },
    mounted () {
      /**
       * Set the selected dimension according to the selected parameters.
       * Case where the user edits an already existing search.
       */
      this.updateSelectedDimension()
    },
    methods: {
      ...mapActions('offers', [
        'setSelectedDimension'
      ]),
      /**
       * Called whenever the dimensions are updated
       * @function updateSelectedDimension
       */
      updateSelectedDimension () {
        const {
          max_length: maxLength,
          max_height: maxHeight,
          max_weight: maxWeight,
          max_width: maxWidth
        } = this.getCurrentSearch
        const foundDimension = this.dimensions.find(({ width, height, length, weight }) => {
          return length.max === maxLength && width.max === maxWidth && height.max === maxHeight && weight.max === maxWeight
        })
        if (foundDimension) {
          this.setSelectedDimension(foundDimension.key)
        } else {
          this.setSelectedDimension(null)
        }
      },
      /**
       * @function selected
       * @param {string} dimension
       */
      selected (dimension) {
        if (this.$matomo) {
          this.$matomo.trackEvent('Freight Searches', 'Selected Vehicle', dimension)
        }

        this.setSelectedDimension(dimension)
        this.$emit('update', this.dimensions.find(v => v.key === dimension))
      }
    }
  })
</script>

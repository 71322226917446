<template>
  <ctk-summary-card
    :title="title"
    class="offer-summary-card"
  >
    <template #pickup>
      <offer-summary-card-direction
        direction="pickup"
        :address="offer && offer.pickup && offer.pickup.address"
        :time-slot="offer && offer.pickup && offer.pickup.time_slot"
        class="tw-mb-2 tw-relative"
        data-test="pickup"
      />
    </template>

    <template #delivery>
      <offer-summary-card-direction
        direction="delivery"
        :address="offer && offer.delivery && offer.delivery.address"
        :time-slot="offer && offer.delivery && offer.delivery.time_slot"
        class="tw-mb-2"
        data-test="delivery"
      />
    </template>

    <template #load>
      <OfferSummaryCardLoad
        :load="offer && offer.load"
        data-test="load"
      />
    </template>

    <template #price>
      <price-chip
        class="tw-text-base"
        data-test="price"
      >
        {{ (offer && offer.pricing && (offer.pricing.price || offer.pricing.carrier_price)) | currency(offer.pricing.currency, $i18n.locale) }}
      </price-chip>
    </template>
  </ctk-summary-card>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkSummaryCard from '@/components/CtkSummaryCard/index.vue'
  import OfferSummaryCardDirection from './_subs/OfferSummaryCardDirection/index.vue'
  import OfferSummaryCardLoad from './_subs/OfferSummaryCardLoad/index.vue'
  import PriceChip from '@/components/CtkPricing/_subs/PriceTotal/_subs/PriceChip/index.vue'

  /**
   * @module component - OfferSummaryCard
   * @param {Object} offer - Offer object
   */
  export default defineComponent({
    name: 'OfferSummaryCard',
    components: {
      CtkSummaryCard,
      OfferSummaryCardDirection,
      OfferSummaryCardLoad,
      PriceChip
    },
    props: {
      title: {
        type: String,
        required: true
      },
      offer: {
        type: Object,
        required: true
      }
    }
  })
</script>

<style scoped>
.offer-summary-card {
  margin-top: 0px;
}
.offer-summary-card /deep/ .offer-summary-card-direction:nth-child(1)::after {
  position: absolute;
  content: '';
  background-image: url('~@/assets/img/division-line.svg');
  height: 32px;
  width: 1px;
  left: 9px;
  top: 20px;
}
.offer-summary-card /deep/ .shipment-load-summary {
  margin-left: 0.25rem;
}
</style>

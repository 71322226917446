<template>
  <router-link
    :to="{
      name: 'Searches',
      params: {
        uuid: search.uuid
      }
    }"
    :title="search.name"
    :disabled="$wait.is(`deleting ${search.uuid} search item`)"
    :event="$wait.is(`deleting ${search.uuid} search item`) ? '' : 'click'"
    class="search-item search-container tw-relative tw-bg-white tw-cursor-pointer tw-flex tw-items-center tw-text-secondary-text hover:tw-text-secondary-text"
    @click.native="select"
  >
    <search-item-alert
      :search="search"
      :disabled="$wait.is(`deleting ${search.uuid} search item`)"
    />

    <p
      v-text="search.name"
      class="search-container-name tw-truncate tw-m-0 tw-flex-1"
    />

    <b-dropdown
      :disabled="$wait.is(`deleting ${search.uuid} search item`)"
      variant="link"
      dropdown
      no-caret
      class="ctk-dropdown tw-flex tw-items-center"
    >
      <template #button-content>
        <div class="tw-flex tw-m-auto">
          <ui-material-icon
            data-test="more"
            name="more_vert"
          />
        </div>
      </template>
      <b-dropdown-item @click.stop="edit(search)">
        {{ $t('modify_search') | capitalize }}
      </b-dropdown-item>
      <b-dropdown-item @click.stop="duplicate(search)">
        {{ $t('duplicate_search') }}
      </b-dropdown-item>
      <b-dropdown-item @click.stop="remove(search)">
        {{ $t('app.buttons.delete') }}
      </b-dropdown-item>
    </b-dropdown>

    <div
      :class="{
        'search-item__badge--has-dot': search.unviewed !== null && search.unviewed > 0,
        'search-item__badge--count': search.count && search.count > 0
      }"
      class="search-item__badge tw-text-secondary-text tw-text-xs tw-text-center tw-px-1 tw-flex-shrink-0 tw-relative"
      data-test="badge"
    >
      <div
        v-b-tooltip.hover
        :title="search.unviewed !== null && search.unviewed > 0
          ? $tc('offers.values.unviewed_offers', search.unviewed, {
            count: $n(search.unviewed)
          })
          : null"
        class="search-item__badge__icon tw-rounded-md"
        data-test="dot"
      />
      <span
        v-text="(search.count !== null && (typeof search.count !== 'undefined') && search.count !== '') ? $n(search.count) : '-'"
        class="search-item__badge__content tw-relative"
        data-test="badge"
      />
    </div>

    <ui-loader
      v-if="$wait.is(`deleting ${search.uuid} search item`)"
      type="spinner"
    />
  </router-link>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters, mapActions } from 'vuex'

  import SearchItemAlert from './_subs/SearchItemAlert/index.vue'
  import { Search } from '@/resources'
  import { showToaster } from '@/services/Toaster'
  import { maxLengthInCm } from '@/store/modules/offers/models/SearchModel'

  export default defineComponent({
    name: 'SearchList',
    components: {
      SearchItemAlert
    },
    props: {
      search: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        searchNameMaxLength: 30,
        visible: true
      }
    },
    computed: {
      ...mapGetters('auth', ['getCid']),
      ...mapGetters('offers', [
        'getSavedSearches'
      ])
    },
    methods: {
      ...mapActions('offers', [
        'setCurrentSearch',
        'refreshSearchCount'
      ]),
      /**
       * @function select
       * @param {Event} e
       */
      select (e) {
        /**
         * If the user clicks on the alert button, we do not want
         * to trigger the clicked event at the same time.
         */
        // @ts-ignore
        if (e.target && e.target.className.match('ctk-font')) {
          e.preventDefault()
          return
        }

        if (this.$matomo) {
          this.$matomo.trackEvent('Searches', 'Clicked', this.search.uuid, this.search.count || 0)
        }

        this.$emit('select')
      },
      /**
       * @function edit
       * @param {{
       *  uuid: string
       * }} search
       */
      edit (search) {
        if (this.$matomo) {
          this.$matomo.trackEvent('Searches', 'Initiated Change', search.uuid)
        }

        this.setCurrentSearch({ search: search })
        this.$emit('modify-current-search')
      },
      /**
       * @function remove
       * @param {{
       *  uuid: string
       * }} search
       */
      remove (search) {
        this.$wait.start(`deleting ${search.uuid} search item`)
        this.$store.dispatch('offers/deleteSearch', search)
          .then(() => {
            if (this.$matomo) {
              this.$matomo.trackEvent('Searches', 'Deleted', search.uuid)
            }

            /**
             * During deletion, we want to be sure to redirect the user to
             * the list of offers when we deleted the last search.
             * This avoids keeping the last search on the screen.
             */
            if (this.getSavedSearches.length === 0) {
              this.$router.push({
                name: 'Offers'
              })
                .catch(() => {})
            }
          })
          .catch((err) => {
            if (!err.response) return

            const { data } = err.response
            if (data && data.error) {
              const errorMessage = data.error.detail || data.error.title
              showToaster(this, errorMessage, {
                type: 'error',
                position: 'bottom-right'
              })
            }
          })
          .finally(() => {
            this.$wait.end(`deleting ${search.uuid} search item`)
          })
      },
      /**
       * @function duplicate
       * @param {{
       *  uuid: string
       *  name: string
       * }} search
       */
      duplicate (search) {
        const duplicateSearch = { ...search }
        if (this.$matomo) {
          this.$matomo.trackEvent('Searches', 'Duplicated', duplicateSearch.uuid)
        }

        delete duplicateSearch.uuid
        let name = this.$t('duplicate_search.name_pattern', {
          initial_search_name: search.name
        })
        if (name.length > this.searchNameMaxLength) {
          // @ts-ignore
          const tooManyCharsQuantity = name.length - this.searchNameMaxLength
          name = this.$t('duplicate_search.name_pattern', {
            initial_search_name: search.name.substring(0, search.name.length - tooManyCharsQuantity - 3) + '...'
          })
        }
        duplicateSearch.name = name
        this.saveDuplicateSearch(duplicateSearch)
      },
      /**
       * @function saveDuplicateSearch
       * @param {{
       *  uuid: string
       * }} duplicateSearch
       */
      async saveDuplicateSearch (duplicateSearch) {
        this.$wait.start('duplicating search item')
        const payload = { ...duplicateSearch }

        // there is no need to store max length as if it searches for all length above min length
        if (typeof payload.max_length === 'number' && payload.max_length === maxLengthInCm) {
          delete payload.max_length
        }

        try {
          // @ts-ignore
          const response = await Search.save({ cid: this.getCid }, payload)
          duplicateSearch.uuid = response.data.uuid
          this.$store.commit('offers/ADD_SEARCH_IN_SEARCHES', duplicateSearch)
          this.setCurrentSearch({ search: duplicateSearch })
          this.refreshSearchCount(duplicateSearch)
        } catch (error) {
          console.log('error save search', error)
          showToaster(this, this.$t('error_while_saving_search'), { type: 'error' })
        }
        this.$wait.end('duplicating search item')
      }
    }
  })
</script>

<style lang="scss">
.search-container {
  padding: 5px 0;
  min-height: 45px;
  transition: box-shadow 200ms;
}
.search-container .search-item__badge {
  background-color: $divider;
  min-width: 28px;
}
.search-container .search-item__badge--count {
  --tw-bg-opacity: 1;
  background-color: rgba(117, 116, 116, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.search-container .search-item__badge__icon {
  --tw-bg-opacity: 1;
  background-color: rgba(106, 207, 181, var(--tw-bg-opacity));
  display: none;
  height: 0.5rem;
  position: absolute;
  width: 0.5rem;
  top: -0.25rem;
  right: -0.25rem;
}
.search-container .search-item__badge--has-dot .search-item__badge__icon {
  display: block;
}
.search-container[disabled=disabled] {
  background-color: #E2E2E2 !important;
  cursor: wait;
}
.search-container[disabled=disabled] .search-item__badge {
  opacity: 0.5;
  background-color: darken(#E2E2E2, 20%) !important;
}
.search-container:focus-visible {
  @include focusShadow($info);
  outline: none;
  z-index: 2;
}
.search-container.router-link-active, .search-container:hover {
  text-decoration: none;
  color: $info;
}
.search-container.router-link-active .search-item__badge, .search-container:hover .search-item__badge {
  --tw-bg-opacity: 1;
  background-color: rgba(39, 84, 145, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.search-container .ui-loader {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
</style>

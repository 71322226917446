<template>
  <div
    class="ctk-alert-banners"
    id="alert-banners"
  >
    <ctk-locked-account
      v-if="isAccountLocked"
      data-test="locked"
    />
    <ctk-unpaid-shipments
      v-if="isUserShipper && hasUnpaidShipments"
      data-test="shipments"
    />

    <ctk-email-validation
      v-if="emailAlert"
      :type="emailAlert.key"
      :title="emailAlert.title"
      :text="emailAlert.text"
      :variant="emailAlert.level"
      data-test="email-validation"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import ResizeObserver from 'resize-observer-polyfill'

  import CtkLockedAccount from '@/components/CtkLockedAccount/index.vue'
  import CtkUnpaidShipments from '@/components/CtkUnpaidShipments/index.vue'
  import CtkEmailValidation from '@/components/CtkEmailValidation/index.vue'

  /**
   * @module component - CtkLockedAccount
   */
  export default {
    components: {
      CtkLockedAccount,
      CtkUnpaidShipments,
      CtkEmailValidation
    },
    data () {
      return {
        /** @type {ResizeObserver|null} */
        observer: null
      }
    },
    computed: {
      ...mapGetters([
        'isUserShipper'
      ]),
      ...mapGetters('auth', [
        'isAccountLocked',
        'isCompanyPayingCC'
      ]),
      ...mapGetters('shipments', [
        'hasUnpaidShipments'
      ]),
      ...mapGetters('ui', [
        'getAlerts'
      ]),
      /**
       * @function emailAlert
       * @returns {object}
       */
      emailAlert () {
        const types = ['user_email_awaiting_validation', 'user_email_hard_bounce']
        return this.getAlerts && this.getAlerts.find(alert => types.includes(alert.key))
      },
      /**
       * @function hasUnpaidInvoices
       * @returns {boolean}
       */
      hasUnpaidInvoices () {
        if (!this.getInvoicesMetrics) return false

        const { amounts } = this.getInvoicesMetrics
        if (!amounts || !amounts.payment_pending || !amounts.payment_late) return false

        if (this.isCompanyPayingCC) return true

        if (amounts.payment_late > 0 && amounts.payment_defaulting > 0) return true

        return false
      }
    },
    mounted () {
      this.observer = new ResizeObserver(entries => {
        for (const entry of entries) {
          const { height } = entry.contentRect

          this.setBannersHeight(height)
        }
      })

      if (this.$el && !!this.$el.tagName) this.observer.observe(this.$el)
    },
    methods: {
      ...mapActions('ui', [
        'setBannersHeight'
      ])
    },
    beforeDestroy () {
      if (this.observer) {
        this.observer.disconnect()
      }
    }
  }
</script>

<style lang="scss">

  .ctk-alert-banners .markdown {
    p {
      display: inline;
      margin: 0;
    }
  }

</style>

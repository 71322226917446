/**
 * @typedef SupportedLocale
 * @property {string} locale
 * @property {string} name
 */

/**
 * Exposes a locale list for the app and the tests.
 * This list is the source of truth for the supported locales.
 * @constant supportedLocales
 * @type {Array<SupportedLocale>}
 */
const supportedLocales = [
  {
    locale: 'fr',
    name: 'Français'
  },
  {
    locale: 'en',
    name: 'English'
  },
  {
    locale: 'pl',
    name: 'Polski'
  }
]

/**
 * Exposes a list of supported files for each locale.
 * This list is the source of truth for the supported files.
 * @constant supportedFiles
 * @type {Array<string>}
 */
const supportedFiles = ['missions', 'dashboard', 'faq-icu', 'drivers-icu', 'offers', 'account', 'app', 'geo', 'validator', 'shipments', 'auth']

/**
 * List of locales to preload by default by the app.
 * @constant preloadedLocales
 * @type {Array<string>}
 */
const preloadedLocales = ['en']

/**
 * List of files to be preloaded by default by the app.
 * @constant preloadedFiles
 * @type {Array<string>}
 */
const preloadedFiles = ['auth', 'app', 'validator', 'geo']

module.exports = {
  supportedLocales,
  supportedFiles,
  preloadedLocales,
  preloadedFiles
}

import Vue from 'vue'
import Router from 'vue-router'
import AuthService from '@/services/AuthService'
import store from '@/store'

import EmailValidationMiddleware from './middleware/EmailValidationMiddleware'
import ImpersonateMiddleware from './middleware/ImpersonateMiddleware'
import NotFoundMiddleware from './middleware/NotFoundMiddleware'
import TranslationsMiddleware from './middleware/TranslationsMiddleware'
import AuthMiddleware from './middleware/AuthMiddleware'

import ChunkErrorHandler from './error/ChunkErrorHandler'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  scrollBehavior: () => ({
    x: 0,
    y: 0
  }),
  routes: [
    {
      path: '/',
      name: 'Auth',
      redirect: {
        name: 'Offers'
      }
    },
    {
      path: '/auth',
      name: 'AuthView',
      meta: {
        hasNavbar: false,
        requiresAuth: false
      },
      redirect: {
        name: 'SignIn'
      },
      component: () => import(/* webpackChunkName: "auth" */ '@/views/Common/Auth'),
      children: [
        {
          path: 'sign-in',
          name: 'SignIn',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          },
          component: () =>
            import(/* webpackChunkName: "auth" */ '@/views/Common/Auth/_subs/SignIn')
        },
        {
          path: 'password-forgot',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          },
          name: 'PasswordForgot',
          component: () =>
            import(/* webpackChunkName: "auth" */ '@/views/Common/Auth/_subs/PasswordForgot')
        },
        {
          path: 'renew-password',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          },
          name: 'PasswordReset',
          component: () =>
            import(/* webpackChunkName: "auth" */ '@/views/Common/Auth/_subs/PasswordReset')
        },
        {
          path: 'invite',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          },
          name: 'Invite',
          component: () =>
            import(/* webpackChunkName: "auth" */ '@/views/Common/Auth/_subs/Invite')
        },
        {
          path: 'invite/confirmation',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          },
          name: 'InviteConfirmation',
          component: () =>
            import(/* webpackChunkName: "auth" */ '@/views/Common/Auth/_subs/Invite/_subs/Confirmation')
        },
        {
          path: 'invite/expired',
          meta: {
            hasNavbar: false,
            requiresAuth: false,
            hideRegisterCta: true,
            translations: ['account', 'auth', 'shipments']
          },
          name: 'InviteExpired',
          component: () =>
            import(/* webpackChunkName: "auth" */ '@/views/Common/Auth/_subs/Invite/_subs/Expired'),
          props: true
        },
        {
          path: 'email-validation',
          name: 'EmailValidation',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          }
        },
        {
          path: 'impersonate',
          name: 'Impersonate',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          }
        }
      ]
    },
    {
      path: '/faq',
      name: 'Faq',
      component: () =>
        import(/* webpackChunkName: "faq" */ '@/views/Common/Faq'),
      meta: {
        translations: ['faq-icu']
      }
    },
    {
      path: '/missions',
      name: 'MissionsRouter',
      component: () =>
        import(/* webpackChunkName: "missions" */ '@/views/Carriers/Missions'),
      redirect: () => ({
        name: 'Missions',
        params: {
          state: 'planned'
        }
      }),
      children: [
        {
          path:
            ':state(planned|in_progress|completed|cancelled)',
          name: 'Missions',
          component: () =>
            import(/* webpackChunkName: "missions" */ '@/views/Carriers/Missions/views/Missions')
        },
        {
          path: ':uuid',
          name: 'Mission',
          component: () =>
            import(/* webpackChunkName: "missions" */ '@/views/Carriers/Missions/views/Missions')
        }
      ]
    },
    {
      path: '/offers',
      component: () =>
        import(/* webpackChunkName: "offers" */ '@/views/Carriers/Offers'),
      children: [
        {
          path: '/',
          name: 'Offers',
          component: () =>
            import(/* webpackChunkName: "offers" */ '@/views/Carriers/Offers/views/Offers')
        },
        /**
         * TODO: Remove this route when we're sure no one is using it anymore.
         */
        {
          path: 'map',
          name: 'OffersMap',
          redirect: {
            name: 'Offers'
          }
        },
        {
          path: 'proposals',
          name: 'Proposals',
          component: () =>
            import(/* webpackChunkName: "proposals" */ '@/views/Carriers/Offers/views/Proposals')
        },
        {
          path: 'proposals/:state(pending)',
          name: 'ProposalStates',
          component: () =>
            import(/* webpackChunkName: "proposals" */ '@/views/Carriers/Offers/views/Proposals')
        },
        {
          path: 'searches/:uuid?',
          name: 'Searches',
          component: () =>
            import(/* webpackChunkName: "offers" */ '@/views/Carriers/Offers/views/Offers')
        },
        /**
         * TODO: Remove this route when we're sure no one is using it anymore.
         */
        {
          path: 'searches/:uuid?/map',
          name: 'SearchesMap',
          redirect: {
            name: 'Searches'
          }
        }
      ]
    },
    {
      path: '/account',
      name: 'Account',
      redirect: {
        name: 'AccountProfile'
      },
      component: () =>
        import(/* webpackChunkName: "account" */ '@/views/Common/Account'),
      children: [
        {
          path: 'profile',
          name: 'AccountProfile',
          component: () =>
            import(/* webpackChunkName: "profile" */ '@/views/Common/Account/Profile')
        },
        {
          path: 'password',
          name: 'AccountPassword',
          component: () =>
            import(/* webpackChunkName: "password" */ '@/views/Common/Account/Password')
        },
        {
          path: 'company',
          name: 'AccountCompany',
          component: () =>
            import(/* webpackChunkName: "company" */ '@/views/Common/Account/Company')
        },
        {
          path: 'locale',
          name: 'AccountLocale',
          component: () =>
            import(/* webpackChunkName: "locale" */ '@/views/Common/Account/Locale')
        },
        {
          path: 'members',
          name: 'AccountMembers',
          component: () =>
            import(/* webpackChunkName: "members" */ '@/views/Common/Account/Members')
        }
      ]
    },
    {
      path: '/offers/:uuid',
      name: 'Offer',
      component: () =>
        import(/* webpackChunkName: "offer" */ '@/views/Carriers/Offer')
    },
    {
      path: '/logout',
      name: 'Logout',
      beforeEnter () {
        AuthService.signOut()
      }
    },
    {
      path: '*',
      redirect: { name: 'Offers' }
    }
  ]
})

router.beforeEach(EmailValidationMiddleware)
router.beforeEach(ImpersonateMiddleware)
router.beforeEach(NotFoundMiddleware)
router.beforeEach(TranslationsMiddleware)
router.beforeEach(AuthMiddleware)

router.onError(ChunkErrorHandler)

/**
 * If a "unexpected token" error occurs, that means VueRouter could not
 * load a chunk for some reason. If that's the case, force a page refresh.
 * As seen in: https://stackoverflow.com/questions/59385650/vuejs-browser-caching-and-loading-chunk-failed-issue
 */
window.onerror = function (errorMessage) {
  const isUpdateRequired = store.getters['update/isUpdateRequired']

  if (errorMessage.toLowerCase().indexOf('unexpected token \'<\'') > -1 && isUpdateRequired) {
    if (navigator.onLine) {
      window.location.reload()
    }
  }
}

Vue.prototype.router = router

export default router

<template>
  <b-modal
    id="saveModal"
    v-model="showModal"
    :title="$t('save_new_search')"
    centered
    no-close-on-backdrop
    no-close-on-esc
  >
    <template #modal-header>
      <div
        class="tw-w-full tw-flex tw-items-center tw-justify-between"
      >
        <h5
          class="modal-title tw-m-0"
        >
          {{ $t('save_new_search') }}
        </h5>
        <ui-button
          :title="$t('close') | capitalize"
          class="close-modal !tw-bg-transparent"
          fab
          @click="toogleSaveSearchModal"
        >
          <ui-ctk-icon
            name="close"
            class="tw-block"
            data-test="icon"
          />
        </ui-button>
      </div>
    </template>

    <ValidationObserver
      ref="observer"
      slim
    >
      <ValidationProvider
        :name="$t('name_of_your_search')"
        rules="required|min:1|max:30"
        slim
      >
        <template slot-scope="{ invalid, validated, errors }">
          <ctk-input-text
            id="search-name"
            v-model="tempName"
            :label="$t('name_of_your_search') | capitalize"
            :hint="invalid && errors[0]"
            :error="invalid && validated"
            name="search-name"
            class="mb-4"
            required
            @input="setCurrentSearchName"
          />
        </template>
      </ValidationProvider>
    </ValidationObserver>

    <div
      class="alert-content p-3"
      style="background: #F6F6F6;"
    >
      <h6>{{ $t('do_not_miss_offers') }}</h6>
      <p>{{ $t('do_not_miss_offers_description') }}</p>
      <b-form-checkbox
        v-model="currentSearchAlert"
        data-test="alert"
      >
        {{ $t('save_search_checkbox_label') }}
      </b-form-checkbox>
    </div>

    <template #modal-footer>
      <div
        class="tw-w-full tw-flex tw-justify-between tw-items-center"
      >
        <ui-button
          variant="link"
          class="cancel-button mr-2"
          @click="toogleSaveSearchModal"
        >
          {{ $t('cancel') | capitalize }}
        </ui-button>
        <ui-button
          class="confirm-button"
          variant="primary"
          @click="saveSearch(getCurrentSearch)"
          data-test="confirm"
        >
          {{ $t('confirm') | capitalize }}
        </ui-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'

  export default defineComponent({
    name: 'DialogSaveSearch',
    components: {
      CtkInputText
    },
    props: {
      showModal: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data () {
      return {
        tempName: null
      }
    },
    computed: {
      ...mapGetters('offers', [
        'getCurrentSearch'
      ]),
      currentSearchAlert: {
        get () {
          return this.getCurrentSearch.send_by_email
        },
        set (v) {
          this.setCurrentSearchSendEmail(v)
        }
      }
    },
    watch: {
      showModal (val) {
        if (val) {
          Object.assign(this.$data, this.$options.data.call(this))
          /**
           * Ensure there are any dirty errors when the dialog is opened.
           */
          this.$nextTick(() => {
            this.$refs.observer.reset()
          })
        }
      }
    },
    methods: {
      ...mapActions('offers', [
        'setCurrentSearch'
      ]),
      setCurrentSearchSendEmail (sendByEmail) {
        this.setCurrentSearch({
          search: {
            ...this.getCurrentSearch,
            send_by_email: sendByEmail
          }
        })
      },
      setCurrentSearchName (name) {
        this.setCurrentSearch({
          search: {
            ...this.getCurrentSearch,
            name
          }
        })
      },
      toogleSaveSearchModal () {
        this.$emit('close-modal')
      },
      saveSearch (search) {
        this.$refs.observer.validate()
          .then(valid => {
            if (!valid) return

            this.$emit('save-search', search)
          })
      }
    }
  })
</script>

// @ts-nocheck
export default {
  SET_TOKEN (state, val) {
    state.token = val
  },
  SET_IMPERSONATING (state, val) {
    state.isImpersonating = val
  },
  SET_IMPERSONATE (state, val) {
    state.impersonate = val
  },
  SET_USER_INFOS (state, val) {
    state.userInfos = val
  },
  SET_COMPANY_INFOS (state, company) {
    state.company = company
  },
  SET_URL_REDIRECT (state, Url) {
    state.redirectUrl = Url
  }
}

<template>
  <div class="shipment-summary-card-load tw-flex">
    <ui-ctk-icon
      name="pallets"
      class="tw-relative tw--mx-2 tw-text-blue-500 tw-mb-auto"
      data-test="icon"
    />

    <div class="tw-leading-snug tw-flex-1 tw-pl-2 tw-truncate">
      <OfferLoadSummary
        :load="load"
        :pallets="pallets"
        data-test="summary"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import OfferLoadSummary from './_subs/OfferLoadSummary/index.vue'

  /**
   * @module component - OfferSummaryCardLoad
   * @param {object} load
   * @param {Array<any>} pallets
   */
  export default defineComponent({
    name: 'OfferSummaryCardLoad',
    props: {
      load: {
        type: Object,
        required: true
      },
      pallets: {
        type: Array,
        default: null
      }
    },
    components: {
      OfferLoadSummary
    }
  })
</script>
